















































































































































































































































































































import Vue from 'vue'

interface CarPart {
  key: string
  label: string
}

export default Vue.extend({
  props: {
    display: {
      type: String,
      required: true,
    },
    selectedPart: {
      type: Object as () => CarPart,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedPart(newVal) {
      this.updateSelectedPart(newVal)
    },
    type() {
      this.updateSelectedPart(this.selectedPart)
    },
  },
  mounted() {
    this.updateSelectedPart(this.selectedPart)
  },
  methods: {
    updateSelectedPart(carPart: CarPart) {
      const parts = document.querySelectorAll('.is-car-part')

      if (parts.length > 0) {
        parts.forEach((part) => {
          part.classList.remove('is-selected')
          part.classList.remove('has-scratch')
          part.classList.remove('has-score')
          part.classList.remove('has-dent')

          if (`d${carPart.key}` === part.id) {
            switch (this.type) {
              case 0:
                part.classList.add('is-selected')
                break

              case 1:
                part.classList.add('has-scratch')
                break

              case 2:
                part.classList.add('has-score')
                break

              case 3:
                part.classList.add('has-dent')
                break
            }
          }
        })
      }
    },
  },
})
