

















































import Vue from 'vue'
import { ApiResponse, RelatedSaleItem } from '@/interfaces'

interface RegroupData {
  itemNumber: string | null
}

export default Vue.extend({
  props: {
    showRegroupModal: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: Number,
      default: null,
    },
    relatedSaleItems: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      showModal: false,
      regroupData: {} as RegroupData,
      saleItemsSearchList: [] as Record<string, string>[],
    }
  },
  watch: {
    showRegroupModal(newVal) {
      this.showModal = newVal
      this.regroupData = {} as RegroupData
    },
    relatedSaleItems(newVal) {
      this.createRelatedSaleItemsList(newVal)
    },
  },
  methods: {
    saveRegroupData() {
      this.$api
        .put(`/requisitionitems/${this.itemId}/set-regroup-data`, {
          regroupItemNumber: this.regroupData.itemNumber,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$emit('updateItem', apiResponse.data)

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès !',
          })
          this.closeRegroupModal()
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
            this.regroupData = {} as RegroupData
          }
        })
    },
    closeRegroupModal() {
      this.$emit('triggerShowRegroupModal', false)
    },
    createRelatedSaleItemsList(relatedSaleItems: RelatedSaleItem[]) {
      this.saleItemsSearchList = []
      relatedSaleItems?.forEach((saleItem: RelatedSaleItem) => {
        const formattedDescription =
          saleItem?.description && saleItem?.description?.length > 50
            ? `${saleItem?.description?.substring(0, 50)}...`
            : saleItem.description
        this.saleItemsSearchList.push({
          value: saleItem.itemNumber ?? '',
          label: `${saleItem.itemNumber} - ${formattedDescription}`,
        })
      })
    },
    filterSearchSaleItemsList(search: string) {
      return search
        ? this.saleItemsSearchList?.filter((str: Record<string, string>) => str.value === search)
        : this.saleItemsSearchList
    },
    searchSaleItem(search: string, cb: CallableFunction) {
      const results = this.filterSearchSaleItemsList(search)
      cb(results)
    },
    selectSaleItem() {
      const filteredSaleItemsList = this.filterSearchSaleItemsList(this.regroupData.itemNumber ?? '')
      if (filteredSaleItemsList.length === 1) {
        this.regroupData.itemNumber = filteredSaleItemsList.pop()?.value ?? null
      } else {
        this.regroupData.itemNumber = ''
      }
    },
  },
})
