import { render, staticRenderFns } from "./FourThreeIcon.vue?vue&type=template&id=10c81639&scoped=true&"
import script from "./FourThreeIcon.vue?vue&type=script&lang=ts&"
export * from "./FourThreeIcon.vue?vue&type=script&lang=ts&"
import style0 from "./FourThreeIcon.vue?vue&type=style&index=0&id=10c81639&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c81639",
  null
  
)

export default component.exports