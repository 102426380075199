import { render, staticRenderFns } from "./FreeIcon.vue?vue&type=template&id=5cf16451&scoped=true&"
import script from "./FreeIcon.vue?vue&type=script&lang=ts&"
export * from "./FreeIcon.vue?vue&type=script&lang=ts&"
import style0 from "./FreeIcon.vue?vue&type=style&index=0&id=5cf16451&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf16451",
  null
  
)

export default component.exports