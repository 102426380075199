













































































































































































import Vue from 'vue'
import CarDefectUpload from '@/components/CarDefectUpload.vue'
import { CarDefect, RequisitionItem } from '@/interfaces'

interface CarPart {
  key: string
  label: string
}

export default Vue.extend({
  components: { CarDefectUpload },
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: true,
    },
  },
  data() {
    return {
      viewportWidth: 0,
      showDialog: false,
      defect: null as null | CarDefect,
      selectedPart: {},
      parts: [
        {
          key: 'FAVG',
          label: 'Feu avant gauche',
        },
        {
          key: 'FAVD',
          label: 'Feu avant droit',
        },
        {
          key: 'PCAV',
          label: 'Pare-chocs avant',
        },
        {
          key: 'CAL',
          label: 'Calandre',
        },
        {
          key: 'CAPOT',
          label: 'Capot',
        },
        {
          key: 'AAVG',
          label: 'Aile avant gauche',
        },
        {
          key: 'PAREB',
          label: 'Pare-brise',
        },
        {
          key: 'PNAVG',
          label: 'Pneu avant gauche',
        },
        {
          key: 'PAV',
          label: 'Pavillon',
        },
        {
          key: 'PAVG',
          label: 'Porte avant gauche',
        },
        {
          key: 'VAVG',
          label: 'Vitre avant gauche',
        },
        {
          key: 'RG',
          label: 'Rétroviseur gauche',
        },
        {
          key: 'AARG',
          label: 'Aile arrière gauche',
        },
        {
          key: 'PARG',
          label: 'Porte arrière gauche',
        },
        {
          key: 'VARG',
          label: 'Vitre arrière gauche',
        },
        {
          key: 'PNARG',
          label: 'Pneu arrière gauche',
        },
        {
          key: 'BDCG',
          label: 'Bas de caisse gauche',
        },
        {
          key: 'ABAVD',
          label: 'Anti-brouillard avant droit',
        },
        {
          key: 'ABAVG',
          label: 'Anti-brouillard avant gauche',
        },
        {
          key: 'JAVG',
          label: 'Jante avant gauche',
        },
        {
          key: 'JARG',
          label: 'Jante arrière gauche',
        },
        {
          key: 'POAVG',
          label: 'Poignée avant gauche',
        },
        {
          key: 'POARG',
          label: 'Poignée arrière gauche',
        },
      ] as CarPart[],
    }
  },
  mounted() {
    this.viewportWidth = window.innerWidth
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    click(event: Event) {
      const carPart = (event.target as Element)?.parentElement?.id
      let sentDefect = {
        part: carPart,
        type: 0,
        mediafile: null,
      } as CarDefect

      if (carPart) {
        if (this.item.vehicle && this.item.vehicle?.defects !== null) {
          this.item.vehicle.defects.forEach((defect) => {
            if (defect.part === carPart) {
              sentDefect = defect
            }
          })
        }

        this.defect = sentDefect

        this.parts.forEach((part) => {
          if (part.key === carPart) {
            this.selectedPart = part
          }
        })

        this.showDialog = true
      }
    },
    closeModal() {
      this.showDialog = false
    },
    updateItem(item: RequisitionItem) {
      this.$emit('updateItem', item)
    },
    closeAndUpdate(item: RequisitionItem) {
      this.closeModal()
      this.updateItem(item)
    },
  },
})
