





































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, RequisitionItem, ItemFile } from '@/interfaces'
import { Cropper } from 'vue-advanced-cropper'
import FreeIcon from '@/components/Icons/FreeIcon.vue'
import SquareIcon from '@/components/Icons/SquareIcon.vue'
import LandscapeIcon from '@/components/Icons/LanscapeIcon.vue'
import FourThreeIcon from '@/components/Icons/FourThreeIcon.vue'

import 'vue-advanced-cropper/dist/style.css'

interface FileList {
  itemId: number
}

interface ImageURL {
  id: number
  position: number
  url: string
  thumbnail: string
  token: string
  fallbackUrl: string
  fallbackThumbnail: string
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}

interface Boundaries {
  imageSize: {
    width: number
    height: number
  }

  visibleArea: {
    width: number
    height: number
  }
}

export default Vue.extend({
  components: { Cropper, SquareIcon, LandscapeIcon, FourThreeIcon, FreeIcon },
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: false,
      default: () => {
        return {
          id: null,
          requisitionItemFiles: [] as ItemFile[],
        }
      },
    },
  },
  data() {
    return {
      minPosition: 0,
      busy: false,
      itemData: Object.assign({}, this.item ?? {}) as RequisitionItem,
      mediaList: [] as FileList[],
      itemFilesInfo: [] as ImageURL[],
      itemFiles: [] as ItemFile[],
      itemFilesLength: 0,
      itemFilesUrl: [] as string[],
      imageFile: '',
      viewportWidth: 0,
      cropperImage: {
        src: null as string | null,
        type: null as string | null,
      },
      cropperAspectRatio: (1 / 1) as number | Record<string, number>,
      imgLoaded: false,
      imgFilename: null as string | null,
      imgEdit: {
        url: null as string | null,
        token: null as string | null,
        position: null as number | null,
      },
      percent: 0,
      filesToProcess: 0,
      processedFiles: 0,
      status: '',
      showProgressModal: false,
      modalMessage: '',
      uploadedFilesLength: 0,
      currentUploadedFiles: 1,
      filesToUpload: [] as File[],
      fileIndexToUpload: null as null | number,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    item(newVal) {
      this.itemData = Object.assign({}, newVal ?? {})
    },
    itemData: {
      deep: true,
      handler(newVal) {
        if (newVal.requisitionItemFiles !== undefined) {
          this.itemFiles = []
          this.itemData.requisitionItemFiles?.forEach((file) => {
            this.itemFiles.push(file)
          })
        }
      },
    },
    itemFiles: {
      deep: true,
      immediate: true,
      handler() {
        this.itemFilesInfo = this.sortByPosition(this.getFiles('image'))
        this.itemFilesLength = this.itemFilesInfo.length
        this.itemFilesUrl = []
        this.itemFilesInfo.forEach((image: ImageURL) => {
          this.itemFilesUrl.push(image.url)
        })
      },
    },
    fileIndexToUpload(newVal) {
      if (newVal !== null && this.filesToUpload.length > 0 && newVal <= this.filesToUpload.length) {
        this.uploadFile(this.filesToUpload[newVal], this.itemFilesLength + 1)
      }
    },
  },
  mounted() {
    if (this.item) {
      this.itemFiles = []
      this.itemData = Object.assign({}, this.item)
      this.itemData.requisitionItemFiles?.forEach((file) => {
        this.itemFiles.push(file)
      })
      this.itemFilesInfo = this.sortByPosition(this.getFiles('image'))
      this.itemFilesLength = this.itemFilesInfo.length
      this.itemFilesUrl = []
      this.itemFilesInfo.forEach((image: ImageURL) => {
        this.itemFilesUrl.push(image.url)
      })
    }

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    resetForm() {
      this.itemData = {
        description: null,
        requisitionItemFiles: [],
      }
    },
    getFiles(type: string): ImageURL[] {
      let items = [] as ImageURL[]
      this.itemFiles.forEach((itemFile: ItemFile) => {
        if (itemFile.type === type && itemFile.deletedAt == null) {
          items.push({
            id: itemFile.id,
            position: itemFile.position,
            url: itemFile.url,
            thumbnail: itemFile.urlThumb,
            token: `${itemFile.accessToken}`,
            fallbackUrl: `${process.env.VUE_APP_API_URL}/file/${itemFile.accessToken}/full`,
            fallbackThumbnail: `${process.env.VUE_APP_API_URL}/file/${itemFile.accessToken}/thumbnail`,
          })
        }
      })

      return items
    },
    sortByPosition(files: ImageURL[]) {
      return files.sort((file1, file2) => {
        if (file1.position > file2.position) {
          return 1
        }

        if (file1.position < file2.position) {
          return -1
        }

        return 0
      })
    },
    changePosition(image: ImageURL, newPosition: number) {
      const lastPosition = image.position

      const data = {
        files: this.itemFilesInfo,
        imageId: image.id,
        newPosition,
        lastPosition,
      }

      this.$api
        .put(`/requisitionitem/${this.item.id}/file`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Image mise à jour avec succès !',
          })

          this.$emit('itemFileUpdated', apiResponse.data)
          this.itemFiles = apiResponse.data
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    deleteFile(image: ImageURL) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ce fichier ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
        .then(() => {
          this.busy = true
          this.$api.delete(`/requisition/file/${image.id}`).then((response) => {
            const apiResponse = response.data as ApiResponse

            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('itemFileUpdated', apiResponse.data)
            this.itemFiles = apiResponse.data
          })
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    updateInputFile(event: Event) {
      const inputFiles = (event.target as HTMLInputElement).files
      if (!inputFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }
      this.uploadFile(inputFiles[0])
    },
    uploadFile(file: File, position = -1) {
      if (position === -1) {
        position = this.itemFilesLength + 1
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener(
        'load',
        () => {
          let dataurl = ''
          if (reader.result) {
            const canvas = document.createElement('canvas')
            // Creating a canvas to resize later
            let img = document.createElement('img')
            let ctx = canvas.getContext('2d')
            img.src = reader.result?.toString() ?? ''

            img.onload = () => {
              canvas.width = img.width
              canvas.height = img.height
              ctx?.drawImage(img, 0, 0, img.width, img.height)

              let MAX_WIDTH = 2048
              let MAX_HEIGHT = 2048
              let width = img.width
              let height = img.height

              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width
                  width = MAX_WIDTH
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height
                  height = MAX_HEIGHT
                }
              }

              // Clearing canvas...
              ctx?.clearRect(0, 0, canvas.width, canvas.height)

              // ...to refill it with the resized picture
              ctx = canvas.getContext('2d')
              canvas.width = width
              canvas.height = height
              ctx?.drawImage(img, 0, 0, width, height)

              dataurl = canvas.toDataURL('image/jpeg', 0.75).toString().split(',')[1]

              const data = {
                filename: file.name,
                position,
                data: dataurl,
              }

              this.sendFile(data)
            }
          }
        },
        false
      )
    },
    sendFile(data: Record<string, string | number | null>) {
      this.busy = true

      let action = null

      if (this.imgEdit.url !== null) {
        action = this.$api.put(`/requisitionitem/${this.item.id}/file`, data)
      } else {
        action = this.$api.post(`/requisitionitem/${this.item.id}/file`, data)
      }

      action
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          let message = ''
          if (this.imgEdit.url !== null) {
            message = 'Image modifiée avec succès !'
          } else {
            message = 'Image ajoutée avec succès !'
          }

          this.$notify({
            type: 'success',
            title: 'Succès',
            message,
          })

          if (this.imgEdit.url !== null) {
            this.itemFiles = apiResponse.data
          } else {
            this.$emit('itemFileAdded', {
              itemId: this.item.id,
              file: apiResponse.data,
            })
            this.itemFiles.push(apiResponse.data)

            // Increasing index to upload
            if (this.fileIndexToUpload == this.filesToUpload.length - 1) {
              this.fileIndexToUpload = null
            } else {
              if (this.fileIndexToUpload !== null) {
                this.fileIndexToUpload++
              }
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.imgLoaded = false
          this.imgFilename = null
          this.imgEdit = {
            url: null,
            token: null,
            position: null,
          }
          this.processedFiles += 1
          this.percent = Math.round((this.processedFiles / this.filesToProcess) * 100)

          if (this.processedFiles == this.filesToProcess) {
            this.status = 'success'
            setTimeout(() => {
              this.showProgressModal = false
            }, 1000)
          }
          if (this.currentUploadedFiles >= this.filesToProcess) {
            this.$emit('itemFileUpdated', this.itemFiles)
            this.currentUploadedFiles = 1
          }
        })
    },
    cancelCrop() {
      ;(this.$refs.cropper as Cropper).reset()
      this.imgLoaded = false
      this.imgFilename = null
      this.imgEdit = {
        url: null,
        token: null,
        position: null,
      }
    },
    crop() {
      let cropper = null

      if (this.imgEdit.url !== null) {
        cropper = this.$refs.cropperEdit as Cropper
      } else {
        cropper = this.$refs.cropper as Cropper
      }

      const { canvas } = cropper.getResult()

      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob !== null) {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              if (reader.result) {
                // Creating a canvas to resize later
                let img = document.createElement('img')
                let ctx = canvas.getContext('2d')
                img.src = reader.result?.toString() ?? ''
                ctx?.drawImage(img, 0, 0)

                // This image gives us the width/height of the cropped area
                let i = new Image()
                i.src = img.src

                i.onload = () => {
                  let MAX_WIDTH = 2048
                  let MAX_HEIGHT = 2048
                  let width = i.width
                  let height = i.height

                  if (width > height) {
                    if (width > MAX_WIDTH) {
                      height *= MAX_WIDTH / width
                      width = MAX_WIDTH
                    }
                  } else {
                    if (height > MAX_HEIGHT) {
                      width *= MAX_HEIGHT / height
                      height = MAX_HEIGHT
                    }
                  }

                  // Clearing canvas...
                  ctx?.clearRect(0, 0, canvas.width, canvas.height)

                  // ...to refill it with the resized picture
                  ctx = canvas.getContext('2d')
                  canvas.width = width
                  canvas.height = height
                  ctx?.drawImage(img, 0, 0, width, height)

                  let dataurl = canvas.toDataURL('image/jpeg', 0.75)

                  // Sending file
                  this.sendFile({
                    filename: this.imgFilename ?? 'image.jpg',
                    position: this.imgEdit.position !== null ? this.imgEdit.position : this.itemFilesLength + 1,
                    data: dataurl.toString().split(',')[1],
                    token: this.imgEdit.token,
                  })
                }
              }
            }
          }
        }, this.cropperImage.type ?? 'image/jpg')
      }
    },
    reset() {
      this.cropperImage = {
        src: null,
        type: null,
      }

      this.imgEdit = {
        url: null,
        token: null,
        position: null,
      }
    },
    loadImage(event: HTMLInputEvent) {
      // Ensuring we have a file before attempting to read it
      if (event.target && event.target.files) {
        this.filesToProcess = event.target.files.length

        if (this.filesToProcess > 1) {
          this.showProgressModal = true
        }

        let filesToUpload = [] as File[]
        Array.from(event.target.files).forEach((file) => {
          // Revoking the object URL, to allow the garbage collector to destroy the uploaded before file
          if (this.cropperImage.src) {
            URL.revokeObjectURL(this.cropperImage.src)
          }

          filesToUpload.push(file)
          this.currentUploadedFiles++
        })

        this.filesToUpload = filesToUpload
        this.fileIndexToUpload = 0
      }
    },
    getMimeType(file: ArrayBuffer, fallback = null as string | null) {
      const byteArray = new Uint8Array(file).subarray(0, 4)
      let header = ''
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16)
      }
      switch (header) {
        case '89504e47':
          return 'image/png'
        case '47494638':
          return 'image/gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg'
        default:
          return fallback
      }
    },
    setAspectRatio(type: string) {
      switch (type) {
        case 'free':
          this.cropperAspectRatio = 0
          break

        case 'fourthree':
          this.cropperAspectRatio = 4 / 3
          break

        case 'square':
        default:
          this.cropperAspectRatio = 1 / 1
          break

        case 'landscape':
          this.cropperAspectRatio = 16 / 9
          break
      }
    },
    rotate() {
      if (this.imgEdit.url !== null) {
        ;(this.$refs.cropperEdit as Cropper).rotate(90)
      } else {
        ;(this.$refs.cropper as Cropper).rotate(90)
      }
    },
    editImage(image: ImageURL) {
      if (image.fallbackUrl) {
        this.imgLoaded = false
        this.cropperImage = {
          src: null,
          type: null,
        }

        this.imgEdit = {
          url: image.fallbackUrl,
          token: image.token,
          position: image.position,
        }
      }
    },
    defaultSize({ imageSize, visibleArea }: Boundaries) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      }
    },
    setCropperZoom(int: number) {
      if (this.imgLoaded == true) {
        if (int > 0) {
          ;(this.$refs.cropper as Cropper).zoom(1.3)
        } else {
          ;(this.$refs.cropper as Cropper).zoom(0.7)
        }
      } else {
        if (int > 0) {
          ;(this.$refs.cropperEdit as Cropper).zoom(1.3)
        } else {
          ;(this.$refs.cropperEdit as Cropper).zoom(0.7)
        }
      }
    },
    shiftedImages(url: string) {
      if (this.itemFilesUrl[0] == url) {
        return this.itemFilesUrl
      } else {
        let num = 0
        let array = [] as string[]

        while (this.itemFilesUrl[num] !== url) {
          array = this.itemFilesUrl.slice(num + 1).concat(this.itemFilesUrl.slice(0, num + 1))
          num++
        }
        return array
      }
    },
    clickOnCropper() {
      const cropper = this.$refs.cropperFile as HTMLInputElement

      if (cropper) {
        cropper.click()
      }
    },
    dropFile(event: DragEvent) {
      this.resetProgress()
      let droppedFiles = (event.dataTransfer as DataTransfer).files

      if (!droppedFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }

      const droppedFilesArray = [...droppedFiles]

      this.filesToProcess = droppedFilesArray.length

      if (this.filesToProcess > 1) {
        this.showProgressModal = true
      }

      this.modalMessage = 'Envoi des fichiers en cours...'

      this.filesToUpload = droppedFilesArray
      this.fileIndexToUpload = 0
    },
    resetProgress() {
      this.filesToProcess = 0
      this.processedFiles = 0
      this.percent = 0
      this.status = ''
      this.currentUploadedFiles = 1
    },
  },
})
