
































































































































































import Vue from 'vue'
import CarDefectUpload from '@/components/CarDefectUpload.vue'
import { CarDefect, RequisitionItem } from '@/interfaces'

interface CarPart {
  key: string
  label: string
}

export default Vue.extend({
  components: { CarDefectUpload },
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: true,
    },
  },
  data() {
    return {
      viewportWidth: 0,
      showDialog: false,
      defect: null as null | CarDefect,
      selectedPart: {},
      parts: [
        {
          key: 'FARD',
          label: 'Feu arrière droit',
        },
        {
          key: 'FARG',
          label: 'Feu arrière gauche',
        },
        {
          key: 'COFFRE',
          label: 'Coffre',
        },
        {
          key: 'PCAR',
          label: 'Pare-chocs arrière',
        },
        {
          key: 'LAR',
          label: 'Lunette arrière',
        },
        {
          key: 'AARD',
          label: 'Aile arrière droite',
        },
        {
          key: 'PARD',
          label: 'Porte arrière droite',
        },
        {
          key: 'PAVD',
          label: 'Porte avant droite',
        },
        {
          key: 'AAVD',
          label: 'Aile avant droite',
        },
        {
          key: 'BDCD',
          label: 'Bas de caisse droit',
        },
        {
          key: 'PAVB',
          label: 'Pavillon',
        },
        {
          key: 'VARD',
          label: 'Vitre arrière droite',
        },
        {
          key: 'VAVD',
          label: 'Vitre avant droite',
        },
        {
          key: 'RD',
          label: 'Rétroviseur droit',
        },
        {
          key: 'PNAVD',
          label: 'Pneu avant droit',
        },
        {
          key: 'PNARD',
          label: 'Pneu arrière droit',
        },
        {
          key: 'JAVD',
          label: 'Jante avant droite',
        },
        {
          key: 'JARD',
          label: 'Jante arrière droite',
        },
        {
          key: 'POAVD',
          label: 'Poignée avant droite',
        },
        {
          key: 'POARD',
          label: 'Poignée arrière droite',
        },
      ] as CarPart[],
    }
  },
  mounted() {
    this.viewportWidth = window.innerWidth
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    click(event: Event) {
      const carPart = (event.target as Element)?.parentElement?.id
      let sentDefect = {
        part: carPart,
        type: 0,
        mediafile: null,
      } as CarDefect

      if (carPart) {
        if (this.item.vehicle && this.item.vehicle?.defects !== null) {
          this.item.vehicle.defects.forEach((defect) => {
            if (defect.part === carPart) {
              sentDefect = defect
            }
          })
        }

        this.defect = sentDefect

        this.parts.forEach((part) => {
          if (part.key === carPart) {
            this.selectedPart = part
          }
        })

        this.showDialog = true
      }
    },
    closeModal() {
      this.showDialog = false
    },
    updateItem(item: RequisitionItem) {
      this.$emit('updateItem', item)
    },
    closeAndUpdate(item: RequisitionItem) {
      this.closeModal()
      this.updateItem(item)
    },
  },
})
